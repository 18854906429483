@charset "UTF-8";

$color-gray_d8d8d8: #d8d8d8;
$color-gray_e5e5e5: #e5e5e5;
$color-red_82171c: #82171c;

$ff-noto_serif: 'Noto Serif JP', serif;

// 下のレイヤーから書く
// https://qiita.com/kojika17/items/b4694e3afe0da97e6335
$z-map: (
  cmn-aside: true,
  cmn-header: true,
  m-contact_modal: true,
  wakana-about_content_modal: true,
);

// z-index
@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  $counter: 1;
  $is-index: 0;
  @if $childname != 0 {
    @return index($getkey, $childname);
  }
  @each $map-key, $map-value in $z-map {
    @if $map-key == $name {
      $is-index: $counter;
    }
    $counter: $counter + 1;
  }
  @return $is-index;
}

// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

// web fontを使う場合
@mixin icon_ {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sp
@mixin sp {
  @media (max-width: 428px) {
    @content;
  }
}
