@charset "UTF-8";
@import "../mixin";

.bridal-content {
  padding: 0 0 120px;
  @include sp {
    padding: 0 0 80px;
  }
  &--ttl {
    font-size: 32px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    color: #fff;
    max-width: 100%;
    height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 80px;
    background: url('/images/bridal/bg-bridal-content--ttl.png') no-repeat;
    background-size: cover;
    background-position: center center;
    @include sp {
      font-size: 20px;
      height: 128px;
      margin: 0 0 48px;
    }
  }
  &--inner {
    width: 1000px;
    margin: 0 auto;
    padding-top: 80px;
    @include sp {
      width: 100%;
      margin: 0;
      padding-top: 50px;
    }
  }
  &--text {
    font-size: 20px;
    font-family: $ff-noto_serif;
    line-height: 1.7;
    letter-spacing: 0.1em;
    text-align: center;
    margin: 0 0 96px;
    @include sp {
      font-size: 16px;
      margin: 0 0 64px;
    }
  }
  &--box {
    margin: 120px 0 0;
    @include sp {
      margin: 80px 0 0;
    }
  }
  &--lead {
    font-family: $ff-noto_serif;
    font-weight: normal;
    text-align: center;
    font-size: 28px;
    line-height: 1.5;
    letter-spacing: 0.1em;
  }
  &--desc {
    font-family: $ff-noto_serif;
    text-align: center;
    font-size: 18px;
    line-height: 1.8;
    margin-top: 27px;
    letter-spacing: 0.1em;
  }
}

.bridal-content_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 120px 0 0;
  &:first-of-type {
    margin: 0;
  }
  @include sp {
    display: block;
    margin: 80px 0 0;
  }
  &:nth-child(even) {
    .bridal-content_box--img {
      order: 1;
      @include sp {
        margin: 0 auto 24px 0;
      }
    }
    .bridal-content_box--description {
      order: 2;
    }
  }
  &:nth-child(odd) {
    .bridal-content_box--img {
      order: 2;
      @include sp {
        margin: 0 0 24px auto;
      }
    }
    .bridal-content_box--description {
      order: 1;
    }
  }
  &--img {
    width: 540px;
    display: block;
    @include sp {
      width: calc(100% - 48px);
    }
  }
  &--description {
    width: 400px;
    @include sp {
      width: calc(100% - 48px);
      margin: 0 auto;
    }
  }
  &--ttl {
    font-size: 40px;
    font-family: $ff-noto_serif;
    font-weight: normal;
    color: #A77D42;
    line-height: 0.5;
    letter-spacing: 0.05em;
    margin: 0 0 32px;
    @include sp {
      font-size: 32px;
    }
  }
  &--lead {
    font-size: 18px;
    font-family: $ff-noto_serif;
    color: #A77D42;
    line-height: 0.5;
    letter-spacing: 0.1em;
    display: block;
    margin: 0 0 32px;
    @include sp {
      font-size: 16px;
      line-height: 1.5;
      margin: 0 0 24px;
    }
  }
  &--text {
    font-size: 15px;
    line-height: 1.7;
    letter-spacing: 0.025em;
    margin: 0 0 32px;
    @include sp {
      font-size: 14px;
    }
  }
  &--link {
    font-family: $ff-noto_serif;
    font-size: 15px;
    letter-spacing: 0.1em;
    color: #333;
    display: block;
    text-decoration: none;
    width: 200px;
    padding-bottom: 8px;
    position: relative;
    transition: 0.2s;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      transform: rotate(45deg);
      top: calc(50% - 8px);
      right: 0;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #000;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover {
      opacity: 0.8;
    }
    @include sp {
      font-size: 13px;
    }
  }
}
.bridal-content_gray {
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 96px;
  background-color: #f3f3f3;
  @include sp {
    margin-top: 36px;
    padding-top: 44px;
    padding-bottom: 80px;
  }
  &--inner {
    width: 1000px;
    margin: 0 auto;
    @include sp {
      width: 100%;
      margin: 0;
    }
  }
}
.bridal-content_gray_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  &+& {
    margin-top: 120px;
  }
  &.テレコ {
    flex-direction: row-reverse;
  }
  @include sp {
    display: block;
    &+& {
    margin-top: 80px;
  }
  }
  //&:nth-child(odd) {
  //  .bridal-content_box--img {
  //    order: 1;
  //    @include sp {
  //      margin: 0 auto 24px 0;
  //    }
  //  }
  //  .bridal-content_box--description {
  //    order: 2;
  //  }
  //}
  //&:nth-child(even) {
  //  .bridal-content_box--img {
  //    order: 2;
  //    @include sp {
  //      margin: 0 0 24px auto;
  //    }
  //  }
  //  .bridal-content_box--description {
  //    order: 1;
  //  }
  //}
  &--img {
    width: 540px;
    display: block;
    @include sp {
      width: calc(100% - 48px);
      .テレコ & {
        margin-left: auto;
      }
    }
  }
  &--description {
    width: 400px;
    @include sp {
      width: calc(100% - 48px);
      margin: 0 auto;
      margin-top: 38px;
    }
  }
  &--ttl {
  }
  &--ttl_img {
    display: block;
    @include sp {
      max-height: 23px;
    }
  }
  &--lead {
    font-size: 18px;
    font-family: $ff-noto_serif;
    color: #A77D42;
    line-height: 1;
    letter-spacing: 0.1em;
    display: block;
    margin-top: 24px;
    @include sp {
      letter-spacing: 0;
      font-size: 16px;
      line-height: 1.5;
      margin-top: 20px;
    }
  }
  &--text {
    font-size: 15px;
    line-height: 1.7;
    letter-spacing: 0.025em;
    margin-top: 32px;
    margin-bottom: 0;
    @include sp {
      font-size: 14px;
      margin-top: 24px;
    }
  }
}