@charset "UTF-8";
.bridal-content {
  padding: 0 0 120px;
}

@media (max-width: 428px) {
  .bridal-content {
    padding: 0 0 80px;
  }
}

.bridal-content--ttl {
  font-size: 32px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  letter-spacing: 0.1em;
  color: #fff;
  max-width: 100%;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 80px;
  background: url("/images/bridal/bg-bridal-content--ttl.png") no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 428px) {
  .bridal-content--ttl {
    font-size: 20px;
    height: 128px;
    margin: 0 0 48px;
  }
}

.bridal-content--inner {
  width: 1000px;
  margin: 0 auto;
  padding-top: 80px;
}

@media (max-width: 428px) {
  .bridal-content--inner {
    width: 100%;
    margin: 0;
    padding-top: 50px;
  }
}

.bridal-content--text {
  font-size: 20px;
  font-family: "Noto Serif JP", serif;
  line-height: 1.7;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 96px;
}

@media (max-width: 428px) {
  .bridal-content--text {
    font-size: 16px;
    margin: 0 0 64px;
  }
}

.bridal-content--box {
  margin: 120px 0 0;
}

@media (max-width: 428px) {
  .bridal-content--box {
    margin: 80px 0 0;
  }
}

.bridal-content--lead {
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  text-align: center;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.bridal-content--desc {
  font-family: "Noto Serif JP", serif;
  text-align: center;
  font-size: 18px;
  line-height: 1.8;
  margin-top: 27px;
  letter-spacing: 0.1em;
}

.bridal-content_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 120px 0 0;
}

.bridal-content_box:first-of-type {
  margin: 0;
}

@media (max-width: 428px) {
  .bridal-content_box {
    display: block;
    margin: 80px 0 0;
  }
}

.bridal-content_box:nth-child(even) .bridal-content_box--img {
  order: 1;
}

@media (max-width: 428px) {
  .bridal-content_box:nth-child(even) .bridal-content_box--img {
    margin: 0 auto 24px 0;
  }
}

.bridal-content_box:nth-child(even) .bridal-content_box--description {
  order: 2;
}

.bridal-content_box:nth-child(odd) .bridal-content_box--img {
  order: 2;
}

@media (max-width: 428px) {
  .bridal-content_box:nth-child(odd) .bridal-content_box--img {
    margin: 0 0 24px auto;
  }
}

.bridal-content_box:nth-child(odd) .bridal-content_box--description {
  order: 1;
}

.bridal-content_box--img {
  width: 540px;
  display: block;
}

@media (max-width: 428px) {
  .bridal-content_box--img {
    width: calc(100% - 48px);
  }
}

.bridal-content_box--description {
  width: 400px;
}

@media (max-width: 428px) {
  .bridal-content_box--description {
    width: calc(100% - 48px);
    margin: 0 auto;
  }
}

.bridal-content_box--ttl {
  font-size: 40px;
  font-family: "Noto Serif JP", serif;
  font-weight: normal;
  color: #A77D42;
  line-height: 0.5;
  letter-spacing: 0.05em;
  margin: 0 0 32px;
}

@media (max-width: 428px) {
  .bridal-content_box--ttl {
    font-size: 32px;
  }
}

.bridal-content_box--lead {
  font-size: 18px;
  font-family: "Noto Serif JP", serif;
  color: #A77D42;
  line-height: 0.5;
  letter-spacing: 0.1em;
  display: block;
  margin: 0 0 32px;
}

@media (max-width: 428px) {
  .bridal-content_box--lead {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 24px;
  }
}

.bridal-content_box--text {
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0.025em;
  margin: 0 0 32px;
}

@media (max-width: 428px) {
  .bridal-content_box--text {
    font-size: 14px;
  }
}

.bridal-content_box--link {
  font-family: "Noto Serif JP", serif;
  font-size: 15px;
  letter-spacing: 0.1em;
  color: #333;
  display: block;
  text-decoration: none;
  width: 200px;
  padding-bottom: 8px;
  position: relative;
  transition: 0.2s;
}

.bridal-content_box--link:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  top: calc(50% - 8px);
  right: 0;
}

.bridal-content_box--link:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bridal-content_box--link:hover {
  opacity: 0.8;
}

@media (max-width: 428px) {
  .bridal-content_box--link {
    font-size: 13px;
  }
}

.bridal-content_gray {
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 96px;
  background-color: #f3f3f3;
}

@media (max-width: 428px) {
  .bridal-content_gray {
    margin-top: 36px;
    padding-top: 44px;
    padding-bottom: 80px;
  }
}

.bridal-content_gray--inner {
  width: 1000px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .bridal-content_gray--inner {
    width: 100%;
    margin: 0;
  }
}

.bridal-content_gray_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.bridal-content_gray_box + .bridal-content_gray_box {
  margin-top: 120px;
}

.bridal-content_gray_box.テレコ {
  flex-direction: row-reverse;
}

@media (max-width: 428px) {
  .bridal-content_gray_box {
    display: block;
  }
  .bridal-content_gray_box + .bridal-content_gray_box {
    margin-top: 80px;
  }
}

.bridal-content_gray_box--img {
  width: 540px;
  display: block;
}

@media (max-width: 428px) {
  .bridal-content_gray_box--img {
    width: calc(100% - 48px);
  }
  .テレコ .bridal-content_gray_box--img {
    margin-left: auto;
  }
}

.bridal-content_gray_box--description {
  width: 400px;
}

@media (max-width: 428px) {
  .bridal-content_gray_box--description {
    width: calc(100% - 48px);
    margin: 0 auto;
    margin-top: 38px;
  }
}

.bridal-content_gray_box--ttl_img {
  display: block;
}

@media (max-width: 428px) {
  .bridal-content_gray_box--ttl_img {
    max-height: 23px;
  }
}

.bridal-content_gray_box--lead {
  font-size: 18px;
  font-family: "Noto Serif JP", serif;
  color: #A77D42;
  line-height: 1;
  letter-spacing: 0.1em;
  display: block;
  margin-top: 24px;
}

@media (max-width: 428px) {
  .bridal-content_gray_box--lead {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 20px;
  }
}

.bridal-content_gray_box--text {
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0.025em;
  margin-top: 32px;
  margin-bottom: 0;
}

@media (max-width: 428px) {
  .bridal-content_gray_box--text {
    font-size: 14px;
    margin-top: 24px;
  }
}
